import React, { useEffect, useState } from 'react';
import './App.css';
import TextoLetreado from './components/TextoLetreado/TextoLetreado';

function App() {
  const [showSectionCero, setShowSectionCero] = useState(true);
  const [showSectionOne, setShowSectionOne] = useState(false);
  const [startTyping, setStartTyping] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [language, setLanguage] = useState("es"); // Estado para manejar el idioma

  const languagesData = {
    es: [
      {
        parrafo:
          "Soy Alan Villar, un Ingeniero en software con una gran pasión por la tecnología, especialmente en el desarrollo front-end. Concluí mis estudios en la Universidad Politécnica de Pachuca como Ingeniero en Software, y desde entonces he mantenido un fuerte compromiso con la excelencia en mi trabajo."
      },
      {
        parrafo:
          "Me encanta ser creativo y encontrar soluciones innovadoras para los desafíos del desarrollo de software. Disfruto aprender y mejorar constantemente mis habilidades, y me entusiasma la idea de aportar mi conocimiento y energía a un equipo dinámico donde pueda contribuir significativamente y seguir creciendo profesionalmente."
      },
      {
        parrafo:
          "Además de mi amor por la tecnología, soy un ávido jugador de videojuegos y un gran aficionado a la música. Disfruto escuchar buena música mientras trabajo o cuando me relajo en mi lugar favorito, El Xathe, Atotonilco el Grande, Hgo. También tengo una gatita llamada Moka, que siempre está a mi lado, aportando un toque especial a mi vida diaria."
      },
      {
        parrafo:
          "Estoy lleno de entusiasmo y muchas ganas de trabajar, listo para enfrentar nuevos desafíos y lograr grandes cosas en el mundo del software."
      },
    ],
    en: [
      {
        parrafo:
          "I'm Alan Villar, a Software Engineer with a great passion for technology, especially in front-end development. I completed my studies at Universidad Politécnica de Pachuca as a Software Engineer, and since then, I have maintained a strong commitment to excellence in my work."
      },
      {
        parrafo:
          "I love being creative and finding innovative solutions to software development challenges. I enjoy learning and constantly improving my skills, and I’m excited about contributing my knowledge and energy to a dynamic team where I can make a significant impact and continue growing professionally."
      },
      {
        parrafo:
          "Besides my love for technology, I'm an avid gamer and a great music enthusiast. I enjoy listening to good music while I work or when I relax at my favorite place, El Xathe, Atotonilco el Grande, Hgo. I also have a cat named Moka, who is always by my side, adding a special touch to my daily life."
      },
      {
        parrafo:
          "I am full of enthusiasm and eager to work, ready to face new challenges and achieve great things in the software world."
      },
    ]
  };


  const parrafos = languagesData[language]; // Selecciona los párrafos según el idioma

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    const timeout = setTimeout(() => {
      setShowSectionCero(false);
      setShowSectionOne(true);
      document.body.style.overflow = 'hidden';
    }, 5000);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', checkIfMobile);
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleAcercaDeMiClick = () => {
    setStartTyping(true);
  };

  const handleLanguageToggle = () => {
    setLanguage(prevLanguage => (prevLanguage === "es" ? "en" : "es"));
  };

  return (
    <>
      {showSectionCero && (
        <section id="cero" className="spotlight bottom">
          {Array(100).fill(0).map((_, i) => (
            <div key={i} className='star'></div>
          ))}
          <div className='welcome'>
            <h1>Welcome to Alan Villar's Website</h1>
            <div className="loader"></div>
          </div>
        </section>
      )}
      {showSectionOne && (
        <section id='one' className='spotlight bottom'>
          {Array(100).fill(0).map((_, i) => (
            <div key={i} className='star'></div>
          ))}
          <div className='container'>
            <div className='row'>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <div className='menu-container'>
                  <a
                    className='ithem-menu'
                    href="#two"
                    target='_self'
                    onClick={handleAcercaDeMiClick}
                  >
                    {language === "es" ? "Acerca de mí" : "About Me"}
                  </a>
                  <a
                    className='ithem-menu'
                    href={
                      language === "es"
                        ? "./assets/pdf/AlanVillar-CV-octubre-2024.pdf"
                        : "./assets/pdf/AlanVillar-CV-octubre-2024-ing.pdf"
                    }
                    target='_blank'
                    download
                  >
                    {language === "es" ? "Descargar CV" : "Download CV"}
                  </a>
                  <a
                    className='ithem-menu'
                    href={
                      language === "es"
                        ? "https://wa.me/527711030518?text=Hola,%20he%20visto%20tu%20sitio%20web%20y%20me%20gustaría%20ponerme%20en%20contacto%20contigo."
                        : "https://wa.me/527711030518?text=Hello,%20I%20saw%20your%20website%20and%20would%20like%20to%20get%20in%20touch%20with%20you."
                    }
                    target='_blank'
                    rel="noopener noreferrer"
                  >
                    {language === "es" ? "Ponte en contacto conmigo" : "Contact Me"}
                  </a>
                  <button onClick={handleLanguageToggle} className="language-toggle">
                    {language === "es" ? "Switch to English" : "Cambiar a Español"}
                  </button>
                </div>
              </div>
              <div className='col-md-4'></div>
            </div>
          </div>
        </section>
      )}
      {startTyping && (
        <section
          id='two'
          className='spotlight bottom'
          style={isMobile ? { overflowY: 'auto', maxHeight: '100vh' } : {}}
        >
          {Array(100).fill(0).map((_, i) => (
            <div key={i} className='star'></div>
          ))}
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <TextoLetreado parrafos={parrafos} start={startTyping} />
                <div className='container-back-menu'>
                  <a className='back-menu' href='#one'>
                    {language === "es" ? "Regresar" : "Back"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default App;
