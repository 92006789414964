import React, { useState, useEffect } from 'react';

const TextoLetreado = ({ parrafos, start }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    if (!start || parrafos.length === 0) return;

    const totalDuration = 10000;
    const interval = totalDuration / parrafos.reduce((acc, p) => acc + p.parrafo.length, 0);
    let currentLength = 0;
    let timeoutId;

    const mostrarTexto = () => {
      let text = '';
      parrafos.forEach((p, index) => {
        for (let i = 0; i <= p.parrafo.length; i++) {
          timeoutId = setTimeout(() => {
            setDisplayText(text + p.parrafo.substring(0, i));
            if (i === p.parrafo.length && index < parrafos.length - 1) {
              text += p.parrafo + '\n';
              setDisplayText(text);
            }
          }, currentLength + i * interval);
        }
        currentLength += p.parrafo.length * interval;
      });
    };

    mostrarTexto();

    return () => clearTimeout(timeoutId);
  }, [parrafos, start]);

  return (
    <div className='texto-letreado'>
      {displayText.split('\n').map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </div>
  );
};

export default TextoLetreado;
